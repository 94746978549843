//Accordions
.accordion-list {
    .accordion-item {
        border-radius: $border-radius;
        border: 1px solid $border-color;
        &:not(:last-child) {
           margin-bottom: .5rem;
        }
    }

    .accordion-body {
        padding:1rem;
        padding-top: .5rem;
    }

    .accordion-header {
        .accordion-button {
            font-weight: $headings-font-weight;
            display: flex;
            align-items: center;
            padding: .75rem 1rem;

            &::after {
                content: "\e5cf";
                font-family: "Material Icons";
                margin-left: auto;
                font-size: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                transition: all .25s;
            }

            &[aria-expanded=true] {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}