.btn{
    text-transform: capitalize;
    letter-spacing: -.011rem;
    --#{$prefix}btn-focus-box-shadow: 0px 4px 11px rgba(var(--#{$prefix}btn-focus-shadow-rgb), .175);
    &:hover{
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
}

//Btn hover arrow
.btn-hover{
    .hover-translate{
        transition: transform .25s;
    }
    &:hover{
        .hover-translate{
            transform: translateX(5px);
        }
    }
}