@import "./node_modules/bootstrap/scss/functions";
@import "./custom/variables";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/maps";
@import "./node_modules/bootstrap/scss/mixins";
@import "./custom/utilities/utilities";
@import "./node_modules/bootstrap/scss/utilities";

@import "./node_modules/bootstrap/scss/bootstrap.scss";

//Utilities
@import "./custom/utilities/root";
@import "./custom/utilities/switch-modes";
@import "./custom/utilities/typography";
@import "./custom/components/components";
@import "./custom/forms/forms";




//Update variables for dark mode
body.dark-layout {

    //Common variables
    --#{$prefix}body-color:#{$white};
    --#{$prefix}body-bg:#{$black};
    --#{$prefix}body-bg-rgb: #{to-rgb($black)};
    --#{$prefix}border-color: #{$gray-700};
    --#{$prefix}white-rgb: #{to-rgb($white)};
    --#{$prefix}black-rgb: #{to-rgb($black)};
    --#{$prefix}body-color-rgb: #{to-rgb($white)};
    --#{$prefix}link-color: #{(tint-color($primary,25%))};
    --#{$prefix}link-hover-color: #{$primary};
    --#{$prefix}card-bg:#{$gray-900};
    --#{$prefix}input-group-addon-bg:#{$gray-600};

    .bg-body {
        --#{$prefix}bg-opacity: 1;
        --#{$prefix}body-bg-rgb: #{to-rgb($black)};
        background-color: rgba(var(--#{$prefix}body-bg-rgb), var(--#{$prefix}bg-opacity)) !important;
    }

    .text-body {
        --#{$prefix}body-color-rgb: #{to-rgb($white)};
        color: rgba(var(--#{$prefix}body-color-rgb), var(--#{$prefix}text-opacity)) !important;
    }

    //Cards
    .card {
        --#{$prefix}card-bg:#{$gray-800};
        --#{$prefix}card-cap-bg:#{$gray-800};
        --#{$prefix}card-border-color:#{$gray-700};
        --#{$prefix}card-box-shadow: 0px 4px 16px rgba($black, .75);
    }

    //List & Navs
    .list-group {
        --#{$prefix}list-group-border-color:#{$gray-700};
        --#{$prefix}list-group-bg:#{$gray-800};
        --#{$prefix}list-group-active-bg:#{$gray-700};
        --#{$prefix}list-group-color:#{$gray-100};
        --#{$prefix}list-group-active-color:#{$white};
        --#{$prefix}list-group-action-color:#{$gray-100};
        --#{$prefix}list-group-action-hover-bg:#{$gray-700};
        --#{$prefix}list-group-action-hover-color:#{$white};
        --#{$prefix}list-group-action-active-bg:#{$gray-700};
        --#{$prefix}list-group-action-active-color:#{$white};

    }

    .nav-tabs {
        --#{$prefix}nav-tabs-border-color: #{$gray-800};
    }

    //Modal
    .modal {
        --#{$prefix}modal-bg:#{$gray-900};
    }

    //Divider
    hr {
        border-color: var(--#{$prefix}border-color);
    }

    //Footer
    .footer {
        ul li a {
            color: rgba($white, .75);

            &:hover {
                color: white;

            }
        }
    }

    //Backgrounds
    .bg-style-1 {
        background-color: $gray-900;
    }

    .bg-style-2 {
        background-color: $gray-800;
    }

    .bg-style-3 {
        background-color: $gray-700;
    }

    //Navbar
    .navbar {
        --#{$prefix}navbar-active-color:#{$white};
        --#{$prefix}navbar-color:#{$white};
    }

    .navbar-nav {
        --#{$prefix}nav-link-color:#{$gray-300};
        --#{$prefix}nav-link-hover-color:#{$white};
    }

    //dropdown
    .dropdown-menu {
        --#{$prefix}dropdown-color:#{$gray-300};
        --#{$prefix}dropdown-bg:#{$gray-800};
        --#{$prefix}dropdown-link-color:#{$gray-300};
        --#{$prefix}dropdown-link-hover-color:#{$gray-100};
        --#{$prefix}dropdown-link-hover-bg:#{$gray-700};
        --#{$prefix}dropdown-link-active-color:#{$white};
        --#{$prefix}dropdown-link-active-bg:#{$primary};
    }

    //Buttons
    .btn:not(.btn-light):not(.btn-white):not([class*="btn-outline-"]) {
        --#{$prefix}btn-color: var(--#{$prefix}body-color);
    }

    .btn-close {
        filter: $btn-close-white-filter;
    }

    //Body blur bg on dark mode
    &::before{
        content: "";
        background-image: linear-gradient(155deg, $primary 25%, $pink 50%, $purple 75%, $warning 100%);
        position: fixed;
        filter: blur(150px);
        border-radius: 100%;
        top: 27.5%;
        left: 23.9%;
        width: 270px;
        height: 270px;
        pointer-events: none;
    }

    //accordion
    .accordion-list .accordion-item {
        border-color: var(--#{$prefix}border-color);
    }


    //Forms
    .form-control {
        --#{$prefix}input-color:#{$white};
        --#{$prefix}input-bg: transparent;
        --#{$prefix}input-border-color:#{$gray-500};
        --#{$prefix}input-focus-color:#{$white};
        --#{$prefix}input-focus-bg: transparent;
        --#{$prefix}input-focus-border-color:#{$gray-300};
    }

    //form check
    .form-check-input {
        --#{$prefix}form-check-input-bg: transparent;
        --#{$prefix}form-check-input-border:#{$input-border-width} solid #{$gray-500};
    }

    .choices__list.choices__list--multiple .choices__item.choices__item--selectable {
        background-color: $gray-500;
    }

    .choices__list.choices__list--multiple .choices__button {
        color: white;
    }

    .dropzone {
        --#{$prefix}input-bg: transparent;
        --#{$prefix}input-border-color:#{$gray-500};
    }


    //Offcanvas
    .offcanvas,
    .offcanvas-lg,
    .offcanvas-md,
    .offcanvas-sm,
    .offcanvas-xl,
    .offcanvas-xxl {
        --#{$prefix}offcanvas-bg:#{$gray-800};
    }

    //Pagination
    .pagination{
        //default stats
        --#{$prefix}pagination-bg:#{$gray-700};
        --#{$prefix}pagination-color:#{$gray-300};
        --#{$prefix}pagination-border-color:#{$gray-600};
        //hover stats
        --#{$prefix}pagination-hover-bg:#{$gray-600};
        --#{$prefix}pagination-hover-color:#{$gray-100};
        --#{$prefix}pagination-hover-border-color:#{$gray-600};

        //Focus stats
        --#{$prefix}pagination-focus-bg:#{$gray-600};
        --#{$prefix}pagination-focus-color:#{$gray-100};
        --#{$prefix}pagination-focus-border-color:#{$gray-600};
        --#{$prefix}pagination-focus-box-shadow:0px .125rem .75rem rgba($black,.5);

        //Active Stats
        --#{$prefix}pagination-active-bg:#{$primary};
        --#{$prefix}pagination-active-color:#{$white};
        --#{$prefix}pagination-active-border-color:#{$primary};

        //disabled stats
        --#{$prefix}pagination-disabled-bg:#{$gray-800};
        --#{$prefix}pagination-disabled-color:#{$gray-500};
        --#{$prefix}pagination-disabled-border-color:#{$gray-700};
    }

    //Breadcrumb
    .breadcrumb{
        --#{$prefix}breadcrumb-divider-color:#{$gray-600};
    }
}