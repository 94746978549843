///
/// Custom template typography
/// 
/// 
body{
    letter-spacing: $letter-spacing-body;
}
body,html{
    height: 100%;
}
#__next{
    height: 100%;
}
.display-1,.display-2,.display-3,.display-4,.display-5,.display-6{
    letter-spacing: $letter-spacing-display;
}
b,
strong {
  font-weight: $font-weight-semibold;
}