//Background-styles

.bg-style-1{
    background-color:$bg-style-1;
}
.bg-style-2{
    background-color:$bg-style-2;
}
.bg-style-3{
    background-color:$bg-style-3;
}

//Background
.bg-img{
    background-size:cover;
    background-repeat: no-repeat;
    background-position:top center;
}

//Blur overlay
.bg-blur{
    backdrop-filter: blur(30px);
}