.footer{
    ul{
        li{
            &:not(:last-child){
                margin-bottom: $spacer * .75;
            }
            a{
                display: inline-block;
                color: rgba($body-color,.65);
                &:hover{
                    transition: color .2s;
                    text-decoration: underline;
                    color: $body-color;
                }
            }
        }
    }
}