
//Other utilities
.hover-lift{
  transition: all .25s;
  &:hover,&:focus{
    transform: translateY(-4px);
    
  }
  &:not(.btn):not(.shadow-none){
&:hover{
  box-shadow: $box-shadow-lg!important;
}
  }
}


// Extend bootstrap Utilities

$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(( // scss-docs-start utils-Width in px
        "width-px": (property: width,
            class: width,
            values: (5:5px,
                10:10px,
                15:15px,
                20:20px,
                25:25px,
                30:30px,
                35:35px,
                40:40px,
                45:45px,
                50:50px,
                55:55px,
                60:60px,
                65:65px,
                70:70px,
                75:75px,
                80:80px,
                85:85px,
                90:90px,
                95:95px,
                100:100px,
                110:110px,
                120:120px,
                140:140px,
                180:180px,
                200:200px,
            )),

        // scss-docs-start utils-Height in px
        "height-px": (property: height,
            class: height,
            values: (5:5px,
                10:10px,
                15:15px,
                20:20px,
                25:25px,
                30:30px,
                35:35px,
                40:40px,
                45:45px,
                50:50px,
                55:55px,
                60:60px,
                65:65px,
                70:70px,
                75:75px,
                80:80px,
                85:85px,
                90:90px,
                95:95px,
                100:100px,
                110:110px,
                120:120px,
                140:140px,
                180:180px,
                200:200px,
            )),
// scss-docs-start utils-Height in px
"size-width": (property: width,
class: size,
values: (1:1rem,
2:2rem,
3:3rem,
4:4rem,
5:5rem,
6:6rem,
7:7rem,
8:8rem,
9:9rem,
10:10rem,
11:11rem,
12:12rem,
)),
// scss-docs-start utils-Height in px
"size-height": (property: height,
class: size,
values: (1:1rem,
    2:2rem,
    3:3rem,
    4:4rem,
    5:5rem,
    6:6rem,
    7:7rem,
    8:8rem,
    9:9rem,
    10:10rem,
    11:11rem,
    12:12rem,
)),
"vh-viewport": (
      property: height,
      class: vh,
      values: (
          25:25vh,
          50:50vh,
          75:75vh,
          100: 100vw
          )
    ),
    "width-percentage": (
      property: width,
      responsive:true,
      class: w,
      values: (
          25:25%,
          30:30%,
          35:35%,
          40:40%,
          50:50%,
          60:60%,
          75:75%,
          80:80%,
          100: 100%
          )
    ),
    "z-index": (
      property: z-index,
      class: z-index,
      values: (
          1:1,
          2:2,
          3:3,
          n1: -1,
          fixed:$zindex-fixed
          )
    ),
    "rounded-top": (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: (
        0: 0,
        null: var(--#{$prefix}border-radius)
        )
    ),
    "rounded-end": (
      property: border-top-right-radius border-bottom-right-radius,
      class: rounded-end,
      values: (
        0: 0,
        null: var(--#{$prefix}border-radius)
        )
    ),
    "rounded-bottom": (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: (
        0: 0,
        null: var(--#{$prefix}border-radius)
        )
    ),
    "rounded-start": (
      property: border-bottom-left-radius border-top-left-radius,
      class: rounded-start,
      values: (
        0: 0,
        null: var(--#{$prefix}border-radius)
        )
    ),
    "rounded-top-start": (
      property:border-top-left-radius,
      class: rounded-top-start,
      values: (
        0: 0,
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-2xl)
        )
    ),
    "rounded-top-end": (
      property:border-top-right-radius,
      class: rounded-top-end,
      values: (
        0: 0,
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-2xl)
        )
    ),
    "rounded-bottom-start": (
      property:border-bottom-left-radius,
      class: rounded-bottom-start,
      values: (
        0: 0,
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-2xl)
        )
    ),
    "rounded-bottom-end": (
      property:border-bottom-right-radius,
      class: rounded-bottom-end,
      values: (
        0: 0,
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-2xl)
        )
    ),
    "cursor": (
      property:cursor,
      class: cursor,
      values: (
        auto, grab,pointer
        )
    ),
     // scss-docs-start utils-position
     "position": (
      property: position,
      values: static relative absolute fixed sticky,
      responsive:true
    ),
    "top": (
      property: top,
      values: $position-values,
      responsive:true
    ),
    "bottom": (
      property: bottom,
      values: $position-values,
      responsive:true
    ),
    "start": (
      property: left,
      class: start,
      values: $position-values,
      responsive:true
    ),
    "end": (
      property: right,
      class: end,
      values: $position-values,
      responsive:true
    ),
    ),
    $utilities);

